<template>
  <!--begin::Calendar-->
  <div class="calendar-template" v-if="getPermission('calendar:view')">
    <DetailTemplate customSecondClass="pt-0">
      <template v-slot:header-title>
        <h3 class="custom-header-blue-text font-size-28 text-uppercase">
          Calendar
        </h3>
      </template>
      <template v-slot:header-action>
        <v-btn-toggle>
          <v-btn class="custom-bold-button my-2" color="cyan white--text">
            Calendar
          </v-btn>
          <v-btn
            class="custom-bold-button my-2"
            v-on:click="$router.push(getDefaultRoute('calendar.resource'))"
          >
            Resource
          </v-btn>
        </v-btn-toggle>
      </template>
      <template v-slot:body>
        <v-container fluid class="pt-0">
          <div v-if="eventLoading" class="block-ui">
            <v-progress-circular
              indeterminate
              :size="100"
              :width="8"
              color="cyan"
            ></v-progress-circular>
          </div>
          <v-row class="fill-height custom-border-top">
            <v-col md="9">
              <v-sheet height="64">
                <v-toolbar flat>
                  <v-btn
                    outlined
                    class="mr-4"
                    color="grey darken-2"
                    @click="setToday"
                  >
                    Today
                  </v-btn>
                  <v-btn fab text small color="grey darken-2" @click="prev">
                    <v-icon small> mdi-chevron-left </v-icon>
                  </v-btn>
                  <v-toolbar-title v-if="$refs.calendar">
                    {{ $refs.calendar.title }}
                  </v-toolbar-title>
                  <v-btn fab text small color="grey darken-2" @click="next">
                    <v-icon small> mdi-chevron-right </v-icon>
                  </v-btn>
                  <v-spacer></v-spacer>
                  <v-menu bottom right>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        outlined
                        color="grey darken-2"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <span>{{ typeToLabel[type] }}</span>
                        <v-icon right> mdi-menu-down </v-icon>
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item @click="type = 'day'">
                        <v-list-item-title>Day</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'week'">
                        <v-list-item-title>Week</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = 'month'">
                        <v-list-item-title>Month</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="type = '4day'">
                        <v-list-item-title>4 days</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </v-toolbar>
              </v-sheet>
              <v-sheet height="600">
                <v-calendar
                  ref="calendar"
                  v-model="focus"
                  color="cyan"
                  :events="events"
                  :event-color="getEventColor"
                  :type="type"
                  @click:event="showEvent"
                  @click:more="viewDay"
                  @click:date="viewDay"
                >
                  <template v-slot:day-label="{ day, date }">
                    <div
                      class="py-2 cursor-default"
                      v-on:drop="dropEngineer(date)"
                      v-on:dragover="allowDrop"
                    >
                      <span>{{ day }}</span>
                    </div>
                  </template>
                </v-calendar>
              </v-sheet>
            </v-col>
            <v-col md="3">
              <template v-if="jobLoading">
                <h3
                  class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                  style="line-height: 64px !important"
                >
                  <v-skeleton-loader
                    class="my-5"
                    type="heading"
                  ></v-skeleton-loader>
                </h3>
                <v-simple-table
                  class="table-bordered"
                  fixed-header
                  dense
                  height="600px"
                >
                  <template v-slot:default>
                    <thead>
                      <tr v-for="i in 10" :key="i">
                        <td class="simple-table-th font-size-16" width="100">
                          <v-skeleton-loader
                            class="custom-skeleton py-2 my-0"
                            type="text"
                          ></v-skeleton-loader>
                        </td>
                        <td class="simple-table-th font-size-16">
                          <v-skeleton-loader
                            class="py-2 my-0"
                            type="sentences"
                          ></v-skeleton-loader>
                        </td>
                      </tr>
                    </thead>
                  </template>
                </v-simple-table>
              </template>
              <template v-else>
                <template v-if="unAssignedJobs.length > 0">
                  <h3
                    class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                    style="line-height: 64px !important"
                  >
                    ({{ unAssignedJobs.length }}) Unschedule Jobs
                  </h3>
                  <v-simple-table
                    class="table-bordered"
                    fixed-header
                    dense
                    height="600px"
                  >
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <td class="simple-table-th font-size-16" width="100">
                            Job #
                          </td>
                          <td class="simple-table-th font-size-16">Title</td>
                        </tr>
                      </thead>
                      <tbody>
                        <template v-for="(job, index) in unAssignedJobs">
                          <tr
                            :key="index"
                            draggable="true"
                            v-on:dragstart="dragStart(job)"
                          >
                            <td class="simple-table-td" width="100">
                              <Barcode :barcode="job.barcode"></Barcode>
                            </td>
                            <td class="simple-table-td">{{ job.title }}</td>
                          </tr>
                        </template>
                      </tbody>
                    </template>
                  </v-simple-table>
                </template>
                <template v-else>
                  <h3
                    class="custom-header-blue-text font-size-18 m-0 text-uppercase px-2"
                    style="line-height: 64px !important"
                  >
                    No Unschedule Jobs
                  </h3>
                </template>
              </template>
            </v-col>
          </v-row>
          <template v-if="getPermission('visit:view')">
            <SingleVisitDetail
              v-if="viewVisitDialog"
              :visit.sync="visitId"
              :detail.sync="visitTicketDetail"
              :customer.sync="visitTicketCustomer"
              :property.sync="visitTicketProperty"
              :contactPerson.sync="visitTicketContactPerson"
            ></SingleVisitDetail>
          </template>
          <template v-if="getPermission('visit:create')">
            <AddVisitDialog
              v-if="addVisitDialog"
              :detail.sync="ticketDetail"
              :customer.sync="ticketCustomer"
              :property.sync="ticketProperty"
              :contactPerson.sync="ticketContactPerson"
            ></AddVisitDialog>
          </template>
        </v-container>
      </template>
    </DetailTemplate>
  </div>
  <!--end::Calendar-->
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import DetailTemplate from "@/view/pages/partials/Detail-Template";
import { GET } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import AddVisitDialog from "@/view/pages/job/partials/Add-Visit-Dialog.vue";
import SingleVisitDetail from "@/view/pages/job/partials/Single-Visit-Detail.vue";
import { DialogJobEventBus } from "@/core/lib/job/dialog.job.lib";
import { JobScheduleTeamEventBus } from "@/core/lib/job/job.schedule.team.lib";

export default {
  name: "calendar",
  components: {
    DetailTemplate,
    Barcode,
    SingleVisitDetail,
    AddVisitDialog
  },
  data: () => ({
    eventLoading: true,
    jobLoading: true,
    viewVisitDialog: false,
    addVisitDialog: false,
    visitId: 0,
    visitTicketDetail: new Object(),
    visitTicketCustomer: new Object(),
    visitTicketProperty: new Object(),
    visitTicketContactPerson: new Object(),
    ticketDetail: new Object(),
    ticketCustomer: new Object(),
    ticketProperty: new Object(),
    ticketContactPerson: new Object(),
    focus: "",
    type: "month",
    typeToLabel: {
      month: "Month",
      week: "Week",
      day: "Day",
      "4day": "4 Days"
    },
    events: [],
    unAssignedJobs: []
  }),
  mounted() {
    const _this = this;
    _this.getCalendarEvents();
    _this.getUnassignedVisits();
    if (_this.$refs["calendar"]) {
      _this.$refs["calendar"].checkChange();
    }
    _this.$store.dispatch(SET_BREADCRUMB, [{ title: "Calendar" }]);
    DialogJobEventBus.$on("load:visit", () => {
      _this.getCalendarEvents();
      _this.getUnassignedVisits();
    });
    DialogJobEventBus.$on("load:job", () => {
      _this.visitId = 0;
      _this.viewVisitDialog = false;
      _this.visitTicketDetail = new Object();
      _this.visitTicketCustomer = new Object();
      _this.visitTicketProperty = new Object();
      _this.visitTicketContactPerson = new Object();
    });
    DialogJobEventBus.$on("close:add-visit-dialog", () => {
      _this.addVisitDialog = false;
    });
  },
  beforeDestroy() {
    /*DialogJobEventBus.$off("close:add-visit-dialog");
    DialogJobEventBus.$off("load:visit");
    DialogJobEventBus.$off("load:job");*/
  },
  methods: {
    getCalendarEvents() {
      const _this = this;
      _this.eventLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "calendar/visits"
        })
        .then(({ data }) => {
          _this.events = data;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.eventLoading = false;
        });
    },
    getUnassignedVisits() {
      const _this = this;
      _this.jobLoading = true;
      _this.$store
        .dispatch(GET, {
          url: "job/un-assigned"
        })
        .then(({ data }) => {
          _this.unAssignedJobs = data;
        })
        .catch(error => {
          _this.logError(error);
        })
        .finally(() => {
          _this.jobLoading = false;
        });
    },
    dragStart(row) {
      console.log({ row });
      this.ticketDetail = new Object({
        barcode: row.barcode,
        attention: row.attention,
        description: row.description,
        title: row.title,
        id: row.ticket_id
      });
      this.ticketCustomer = new Object({
        customer: row.customer
      });
      this.ticketProperty = new Object({
        unit_no: row.unit_no,
        street_1: row.street_1,
        street_2: row.street_2,
        zip_code: row.zip_code
      });
      this.ticketContactPerson = new Object({
        primary_phone: row.customer_primary_phone
      });
    },
    dropEngineer(scheduleDate) {
      this.addVisitDialog = true;
      setTimeout(function() {
        JobScheduleTeamEventBus.$emit("update:schedule-date", scheduleDate);
      }, 400);
    },
    allowDrop(event) {
      event.preventDefault();
    },
    viewDay({ date }) {
      this.focus = date;
      this.type = "day";
    },
    getEventColor(event) {
      return event.color;
    },
    setToday() {
      this.focus = "";
    },
    prev() {
      this.$refs.calendar.prev();
    },
    next() {
      this.$refs.calendar.next();
    },
    showEvent({ nativeEvent, event }) {
      const _this = this;
      let visit = _this.lodash.toSafeInteger(event.id);
      _this.$store
        .dispatch(GET, {
          url: "visit/" + visit + "/basic-details"
        })
        .then(({ data }) => {
          _this.visitId = visit;
          _this.viewVisitDialog = true;
          _this.visitTicketDetail = new Object({
            barcode: data.barcode,
            attention: data.attention,
            title: data.title,
            ticket_type: data.ticket_type,
            added_by: {
              display_name: data.added_by_display_name,
              user_email: data.added_by_user_email
            },
            id: data.ticket_id
          });
          _this.visitTicketCustomer = new Object({
            customer: data.customer
          });
          _this.visitTicketProperty = new Object({
            unit_no: data.unit_no,
            street_1: data.street_1,
            street_2: data.street_2,
            zip_code: data.zip_code,
            latitude: data.latitude,
            longitude: data.longitude
          });
          _this.visitTicketContactPerson = new Object({
            primary_phone: data.customer_primary_phone
          });
        })
        .catch(error => {
          _this.logError(error);
        });

      nativeEvent.stopPropagation();
    }
  }
};
</script>
